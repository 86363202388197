export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';
export const USER_SIGNOUT = 'USER_SIGNOUT';

export const WINDOW_RESIZE_COUNT = 'WINDOW_RESIZE_COUNT';
export const ZOOM_IN_ZOOM_OUT_TEACHER_VIDEO = 'ZOOM_IN_ZOOM_OUT_TEACHER_VIDEO';
export const IN_CLASS_STATUS_TEACHER_STUDENT = 'IN_CLASS_STATUS_TEACHER_STUDENT';

export const OPEN_CLOSE_SIGNUP_POPUP = 'OPEN_CLOSE_SIGNUP_POPUP';
export const OPEN_CLOSE_LOGIN_POPUP = 'OPEN_CLOSE_LOGIN_POPUP';
export const OPEN_CLOSE_TEACHER_RATING_POPUP = 'OPEN_CLOSE_TEACHER_RATING_POPUP';
export const OPEN_CLOSE_TEACHER_EDIT_POPUP = 'OPEN_CLOSE_TEACHER_EDIT_POPUP';
export const OPEN_CLOSE_CLASS_ASSIGN_POPUP = 'OPEN_CLOSE_CLASS_ASSIGN_POPUP';

export const ALL_SUBJECT_DATA_LIST_REQUEST = 'ALL_SUBJECT_DATA_LIST_REQUEST';
export const ALL_SUBJECT_DATA_LIST_SUCCESS = 'ALL_SUBJECT_DATA_LIST_SUCCESS';

export const ALL_ATTENDANCE_AND_ASSIGNMENT_REQUEST = 'ALL_ATTENDANCE_AND_ASSIGNMENT_REQUEST';
export const ALL_ATTENDANCE_AND_ASSIGNMENT_SUCCESS = 'ALL_ATTENDANCE_AND_ASSIGNMENT_SUCCESS';

export const ALL_DEMO_CLASSES_REQUEST = 'ALL_DEMO_CLASSES_REQUEST';
export const ALL_DEMO_CLASSES_SUCCESS = 'ALL_DEMO_CLASSES_SUCCESS';
export const ALL_RECORDED_CLASSES_REQUEST = 'ALL_RECORDED_CLASSES_REQUEST';
export const ALL_RECORDED_CLASSES_SUCCESS = 'ALL_REQUESTED_CLASSES_SUCCESS';
export const LATEST_DEMO_CLASSES_REQUEST = 'LATEST_DEMO_CLASSES_REQUEST';
export const LATEST_DEMO_CLASSES_SUCCESS = 'LATEST_DEMO_CLASSES_SUCCESS';
export const ALL_TEACHER_DATA_TO_ASSIGN_CLASS_REQUEST = 'ALL_TEACHER_DATA_TO_ASSIGN_CLASS_REQUEST';
export const ALL_TEACHER_DATA_TO_ASSIGN_CLASS_SUCCESS = 'ALL_TEACHER_DATA_TO_ASSIGN_CLASS_SUCCESS';

export const ALL_CLASS_TO_ASSIGN_CLASS_REQUEST = 'ALL_CLASS_TO_ASSIGN_CLASS_REQUEST';
export const ALL_CLASS_TO_ASSIGN_CLASS_SUCCESS = 'ALL_CLASS_TO_ASSIGN_CLASS_SUCCESS';

export const ALL_SCHOOL_BOARD_DATA_LIST_REQUEST = 'ALL_SCHOOL_BOARD_DATA_LIST_REQUEST';
export const ALL_SCHOOL_BOARD_DATA_LIST_SUCCESS = 'ALL_SCHOOL_BOARD_DATA_LIST_SUCCESS';

export const ALL_TEACHER_DATA_LIST_REQUEST = 'ALL_TEACHER_DATA_LIST_REQUEST';
export const ALL_TEACHER_DATA_LIST_SUCCESS = 'ALL_TEACHER_DATA_LIST_SUCCESS';
export const LATEST_TEACHER_DATA_LIST_REQUEST = 'ALL_TEACHER_DATA_LIST_REQUEST';
export const LATEST_TEACHER_DATA_LIST_SUCCESS = 'ALL_TEACHER_DATA_LIST_SUCCESS';
export const LATEST_SUBSCRIPTION_DATA_LIST_REQUEST = 'LATEST_SUBSCRIPTION_DATA_LIST_REQUEST';
export const LATEST_SUBSCRIPTION_DATA_LIST_SUCCESS = 'LATEST_SUBSCRIPTION_DATA_LIST_SUCCESS';
export const ALL_CLASSES_DATA_LIST_REQUEST = 'ALL_CLASSES_DATA_LIST_REQUEST';
export const ALL_CLASSES_DATA_LIST_SUCCESS = 'ALL_CLASSES_DATA_LIST_SUCCESS';
export const ALL_ADMIN_DASHBOARD_DATA_LIST_REQUEST = 'ALL_ADMIN_DASHBOARD_DATA_LIST_REQUEST';
export const ALL_ADMIN_DASHBOARD_DATA_LIST_SUCCESS = 'ALL_ADMIN_DASHBOARD_DATA_LIST_SUCCESS';
export const TEACHER_ALL_CLASS_LIST_REQUEST = 'TEACHER_ALL_CLASS_LIST_REQUEST';
export const TEACHER_ALL_CLASS_LIST_SUCCESS = 'TEACHER_ALL_CLASS_LIST_SUCCESS';

export const TEACHER_ALL_TODAY_CLASS_LIST_REQUEST = 'TEACHER_ALL_TODAY_CLASS_LIST_REQUEST';
export const TEACHER_ALL_TODAY_CLASS_LIST_SUCCESS = 'TEACHER_ALL_TODAY_CLASS_LIST_SUCCESS';

export const TEACHER_ALL_DEMO_CLASS_LIST_REQUEST = 'TEACHER_ALL_DEMO_CLASS_LIST_REQUEST';
export const TEACHER_ALL_DEMO_CLASS_LIST_SUCCESS = 'TEACHER_ALL_DEMO_CLASS_LIST_SUCCESS';

export const STUDENT_ALL_DEMO_CLASS_LIST_REQUEST = 'STUDENT_ALL_DEMO_CLASS_LIST_REQUEST';
export const STUDENT_ALL_DEMO_CLASS_LIST_SUCCESS = 'STUDENT_ALL_DEMO_CLASS_LIST_SUCCESS';

export const STUDENT_ALL_TODAY_CLASS_LIST_REQUEST = 'STUDENT_ALL_TODAY_CLASS_LIST_REQUEST';
export const STUDENT_ALL_TODAY_CLASS_LIST_SUCCESS = 'STUDENT_ALL_TODAY_CLASS_LIST_SUCCESS';

export const TEACHER_CLASS_ATTEND_WITH_ASSIGNMENT_DATA_REQUEST = 'TEACHER_CLASS_ATTEND_WITH_ASSIGNMENT_DATA_REQUEST';
export const TEACHER_CLASS_ATTEND_WITH_ASSIGNMENT_DATA_SUCCESS = 'TEACHER_CLASS_ATTEND_WITH_ASSIGNMENT_DATA_SUCCESS';

export const EDITOR_ACTIVE_EDITOR_JSON = 'EDITOR_ACTIVE_EDITOR_JSON';

export const ALL_STUDENT_DATA_LIST_REQUEST = 'ALL_STUDENT_DATA_LIST_REQUEST';
export const ALL_STUDENT_DATA_LIST_SUCCESS = 'ALL_STUDENT_DATA_LIST_SUCCESS';
export const ALL_NEW_STUDENT_PROFILE_DATA_LIST_REQUEST = 'ALL_STUDENT_DATA_LIST_REQUEST';
export const ALL_NEW_STUDENT_PROFILE_DATA_LIST_SUCCESS = 'ALL_STUDENT_DATA_LIST_SUCCESS';
export const TODAY_PROFILE_DATA_LIST_REQUEST = 'TODAY_PROFILE_DATA_LIST_REQUEST';
export const TODAY_PROFILE_DATA_LIST_SUCCESS = 'TODAY_PROFILE_DATA_LIST_SUCCESS';
export const LATEST_STUDENT_PROFILE_DATA_LIST_REQUEST = 'ALL_STUDENT_DATA_LIST_REQUEST';
export const LATEST_STUDENT_PROFILE_DATA_LIST_SUCCESS = 'ALL_STUDENT_DATA_LIST_SUCCESS';
export const ALL_STUDENT_SUBSCRIPTION_DATA_LIST_REQUEST = 'ALL_STUDENT_SUBSCRIPTION_DATA_LIST_REQUEST';
export const ALL_STUDENT_SUBSCRIPTION_DATA_LIST_SUCCESS = 'ALL_STUDENT_SUBSCRIPTION_DATA_LIST_SUCCESS';
export const STUDENT_ALL_CLASS_LIST_REQUEST = 'STUDENT_ALL_CLASS_LIST_REQUEST';
export const STUDENT_ALL_CLASS_LIST_SUCCESS = 'STUDENT_ALL_CLASS_LIST_SUCCESS';

export const STUDENT_ALL_TIME_CLASS_LIST_REQUEST = 'STUDENT_ALL_TIME_CLASS_LIST_REQUEST';
export const STUDENT_ALL_TIME_CLASS_LIST_SUCCESS = 'STUDENT_ALL_TIME_CLASS_LIST_SUCCESS';

export const CHAT_MODULE_ALL_STARTED_CALL = 'CHAT_MODULE_ALL_STARTED_CALL';
export const CHAT_MODULE_CURRENT_CALL_GROUP_DATA = 'CHAT_MODULE_CURRENT_CALL_GROUP_DATA';
export const CHAT_MODULE_INCOMING_CALL_GROUP_DATA = 'CHAT_MODULE_INCOMING_CALL_GROUP_DATA';
export const CHAT_MODULE_CURRENT_CALL_ALL_MEMBERS = 'CHAT_MODULE_CURRENT_CALL_ALL_MEMBERS';
export const CHAT_MODULE_NEW_USER_LEAVE_CURRENT_CALL = 'CHAT_MODULE_NEW_USER_LEAVE_CURRENT_CALL';
export const CHAT_MODULE_NEW_USER_ADDED_IN_CURRENT_CALL = 'CHAT_MODULE_NEW_USER_ADDED_IN_CURRENT_CALL';
export const CALL_SOCKET_MESSAGE_BROADCAST = 'CALL_SOCKET_MESSAGE_BROADCAST';

export const ANNOTATOR_UNDO_REDO_CAPTURE = 'ANNOTATOR_UNDO_REDO_CAPTURE';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const CAPTURE_ANNOTATOR_JSON_DATA = 'CAPTURE_ANNOTATOR_JSON_DATA';
export const ANNOTATOR_USER_ON_CAPTURE = 'ANNOTATOR_USER_ON_CAPTURE';
